
body {
	color: black;
}

a {
	color: #ff556f;
	&:visited {
		color: #ff556f;
	}
	&:hover,&:active {
		color: #d6253f;
	}
}

main {
	padding-top: 0;
}

$font-family-headers: Deming-EP, Times New Roman, serif;

h2 {
	text-align: center;
	margin: 4rem auto 6rem;
	font-size: 2em;
	font-family: $font-family-headers;
	text-transform: uppercase;
	letter-spacing: .1rem;

	&.card-style {
		background: white;
		width: 100%;
		padding: 2rem 3rem 1.65rem;
		box-sizing: border-box;

		@include breakpointMin(500px) {
			width: 75%;
			font-size: 3em;
		}

		@include breakpointMin(900px) {
			width: 50%;
		}
	}
}

h3 {
	font-size: 1.4em;
	font-family: $font-family-headers;
	text-transform: uppercase;
	letter-spacing: .1rem;
}

button.cta-button {
	height: 3.6rem;
	margin-top: 3rem;
	background: white;
	border: none;
	color: black;
	padding: 1rem 2rem;
	transition: width 1s;

	&:hover {
		color: black;
		background: $off-white;
	}
}

.art-bg-light { background-color: #ececec; }
.art-bg-dark { 	background-color: #252525; }
.art-bg-red { 	background-color: #d6253f; }
.art-bg-blue { 	background-color: #263892; }
.art-bg-craft { background-color: #c9a278; }

.mobile {
	.art-bg {
		background-attachment: scroll;
	}

	.art-bg-light { background-image: url('../../pm_coffee/img/paper-bg-light.jpg'); }
	.art-bg-dark { 	background-image: url('../../pm_coffee/img/paper-bg-dark.jpg'); }
	.art-bg-red { 	background-image: url('../../pm_coffee/img/paper-bg-red.jpg'); }
	.art-bg-blue { 	background-image: url('../../pm_coffee/img/paper-bg-blue.jpg'); }
	.art-bg-craft { background-image: url('../../pm_coffee/img/paper-bg-craft.jpg'); }

	.video-darken{
		background-image: url('../../pm_coffee/img/paper-bg-dark.jpg');
	}
}

.desktop {
	.art-bg {
		background-attachment: fixed;
		background-size: cover;
		background-position: 100% 0%;
		background-repeat: no-repeat;
	}

	.hq-bg {
		.art-bg-light { background-image: url('../../pm_coffee/img/art-bg-light.jpg'); }
		.art-bg-dark { 	background-image: url('../../pm_coffee/img/art-bg-dark.jpg'); }
		.art-bg-red { 	background-image: url('../../pm_coffee/img/art-bg-red.jpg'); }
		.art-bg-blue { 	background-image: url('../../pm_coffee/img/art-bg-blue.jpg'); }
		.art-bg-craft { background-image: url('../../pm_coffee/img/art-bg-craft.jpg'); }
	}

	.video-darken{
		background-image: url('../../pm_coffee/img/art-bg-dark.jpg');
	}
}

.pmc-section {
	padding: 3rem 0;
	position: relative;


	.pmc-section-content {
		margin: 1rem;

		@include breakpointMin(980px) {
			max-width: 96rem;
			margin: 0 auto;
		}
	}
}

.pmc-logo {
	width: 20rem;
	height: 20rem;
}

.pmc-hero {
	$video-open-speed: 1s;

	background-color: #a7cde9;
	background-image: url('../../pm_coffee/img/hero-bg-static.jpg');
	background-size: cover;
	background-position: center center;
	text-align: center;
	padding: 10rem 0;
	transition: padding $video-open-speed;
	overflow: hidden;


	.bg-video-container {
		position: absolute;
		width: 1150px;
		top: -70px;
		left: 50%;
		margin-left: -575px;
		bottom: -70px;

		*{
			pointer-events: none;
		}

		.bg-video {
			border: none;
			position: absolute;
			right: 0;
			bottom: 0;
			transition: opacity 1s;
			&.no-autoplay{
				opacity: 0;
			}
		}

		@include breakpointMin(1150px) {
			width: auto;
			left: 0;
			right: 0;
			top: 50%;
			margin-left: 0;
			margin-top: -28.125%;
			bottom: auto;
			padding-top: 56.25%;
		}

		.safearea {
			position: absolute;
			top: 70px;
			bottom: 70px;
			left: 0;
			right: 0;
			background: rgba(255,0,0,.5);
		}
	}

	.pmc-section-content {
		position: relative;
		padding: 24rem 0 0;
		transition: padding $video-open-speed;
	}

	.pmc-logo {
		position: absolute;
		transition: left $video-open-speed, margin-left $video-open-speed, width $video-open-speed, height $video-open-speed;
		top: 0;
		left: 50%;
		margin-left: -10rem;
		background: none;
		z-index: 10;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
		}

		&:before {
			top: 10%;
			left: 10%;
			bottom: 10%;
			right: 10%;
			background: black;
			border-radius: 20rem;
			box-shadow: 0 0 5rem 1rem black;
			opacity: .2;
			z-index: 0;
		}

		.icon {
			height: auto;
			position: relative;
			z-index: 1;
		}
	}

	.video-open {
		display: inline-block;
	}

	.video-close {
		display: none;
	}

	.hero-video {
		opacity: 0;
		position: relative;
		width: 100%;
		padding-top: 0;
		background: black;
		transition: opacity calc(#{$video-open-speed} / 2), padding-top $video-open-speed;
	}

	&:not(.video-open){
		.hero-video{
			pointer-events: none;
		}
	}

	.hero-video-container{
		width: 100%;
		height: 100%;
		position:absolute;
		left:0;
		top:0;
	}

	.video-darken {
		opacity: 0;
		transition: opacity $video-open-speed;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		pointer-events: none;
	}

	.close-video {
		width: 5rem;
		height: 5rem;
		position: absolute;
		right: 0;
		top: 5rem;
		font-size: 2.5rem;
		color: white;
		pointer-events: none;
		opacity: 0;
		transition: opacity calc(#{$video-open-speed} / 2);
	}

	&.video-open {

		.video-open {
			display: none;
		}

		.video-close {
			display: inline-block;
		}

		padding-top: 3rem;

		.pmc-logo {
			margin-left: 0;
			left: 0;
			width: 8rem;
			height: 8rem;
		}

		.pmc-section-content {
			position: relative;
			padding: 10rem 0 0;
		}

		.hero-video {
			opacity: 1;
            pointer-events: all;
			padding-top: 56.25%;
			transition: opacity $video-open-speed calc(#{$video-open-speed} / 2), padding-top $video-open-speed;

			iframe {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}

		.video-darken {
			opacity: 1;
		}

		.close-video {
			opacity: 1;
			cursor: pointer;
			pointer-events: auto;
			transition: opacity $video-open-speed calc(#{$video-open-speed} / 2);
		}
	}
}

.pmc-intro {
	padding: 3rem 0;

	h2 {
		text-align: center;
		background: none;
		margin: 0 auto 3rem;
		font-size: 3.6em;
	}
	p {
		font-size: 1.4em;
		margin: 0 auto 0;
		line-height: 1.6em;
	}

	@include breakpointMin(980px) {
		padding: 6rem;

		p {
			font-size: 1.8em;
		}
	}
}

$photo-rotation-deg: 3deg;

.pmc-photo {
	img {
		width: 100%;
		border: 1rem solid white;
		background-color: white;
		box-shadow: .5rem 1rem 5rem rgba(0,0,0,.3);
		box-sizing: border-box;
	}
}

.pmc-story {

	.pmc-story-segment {
		padding: 1rem 0;
		font-size: 1.2em;
		clear: both;

		&:after {
			content: '';
			display: block;
			clear: both;
			overflow: hidden;
		}

		.story-text-group {
			background: white;
			padding: 1rem 2rem;
			position: relative;
			line-height: 1.4em;
			box-shadow: .5rem 1rem 10rem rgba(0,0,0,.3);
			margin-top: 1rem;
		}

		@include breakpointMin(700px) {
			font-size: 1.4em;

			.pmc-photo {
				width: 50%;
				margin-bottom: 1rem;
			}

			.story-text-group {
				margin-top: 5rem;

				p {
					max-width: 48rem;
				}
			}

			&.align-photo-right {
				.pmc-photo {
					float: right;
					transform: rotate($photo-rotation-deg);
				}

				.story-text-group {
					margin-right: calc(50% - 2rem);

					// &:after {
					// 	content: '';
					// 	display: block;
					// 	position: absolute;
					// 	top: 0;
					// 	bottom: 0;
					// 	left: 100%;
					// }
				}
			}

			&.align-photo-left {
				.pmc-photo {
					float: left;
					transform: rotate(calc(#{$photo-rotation-deg} * -1));
				}

				.story-text-group {
					margin-left: calc(50% - 2rem);

					&:after {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 100%;
						background: white;
					}
				}
			}
		}
	}
}

.pmc-mission {

	.pmc-photo {
		text-align: center;
		position: relative;
		z-index: 1;
		margin-bottom: -2rem;

		img {
			width: 90%;
			transform: rotate($photo-rotation-deg);
		}

		@include breakpointMin(700px) {
			margin-bottom: -8rem;

			img {
				width: 50%;
				transform: rotate($photo-rotation-deg);
			}
		}
	}

	.tony-letter {
		font-size: 1.2em;
		padding: 3rem 3rem 2rem;
		height: 40rem;
		overflow: hidden;
		line-height: 1.6em;
		box-shadow: .5rem 1rem 3rem rgba(0,0,0,.5);
		position: relative;

		.show-more {
			cursor: pointer;
			display: block;
			padding-top: 10rem;
			text-align: center;
			position: absolute;
			height: 8rem;
			bottom: 0;
			left: 0;
			right: 0;
			transition: background .5s;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ececec+0,ececec+100&0+0,1+50 */
			background: -moz-linear-gradient(top, rgba(236,236,236,0) 0%, rgba(236,236,236,1) 50%, rgba(236,236,236,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(236,236,236,0) 0%,rgba(236,236,236,1) 50%,rgba(236,236,236,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(236,236,236,0) 0%,rgba(236,236,236,1) 50%,rgba(236,236,236,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ececec', endColorstr='#ececec',GradientType=0 ); /* IE6-9 */

			&:hover {
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+50 */
				background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
			}
		}

		&.expanded {
			height: auto;

			.show-more {
				display: none;
			}
		}

		@include breakpointMin(700px) {
			font-size: 1.4em;
			padding: 10rem 7.5rem;
			margin-bottom: 5rem;
		}

		.signature {
			display: inline-block;
			background-size: contain;
			background-repeat: no-repeat;
			width: 20rem;
			height: 7.6rem;
			background-image: url('../../pm_coffee/img/tony-sig.png');
		}
	}
}

.pmc-details {

	.details {

		.detail-col {
			background: white;
			flex-grow: 1;
			text-align: center;
			font-size: 1.6em;
			padding-bottom: 1rem;

			@include breakpointMax(639px) {

				&:first-child {
					padding-top: 2rem;
				}

				&:last-child {
					padding-bottom: 2rem;
				}
			}
		}

		.detail-icon {
			display: inline-block;
			width: 5rem;
			height: 5rem;
			img {
				width: 100%;
			}
		}

		@include breakpointMin(640px) {
			display: flex;
			justify-content: space-between;

			.detail-col {
				flex-grow: 1;
				text-align: center;
				font-size: 2em;
				flex-basis: 0;
				padding: 4rem 0 3rem;

				p {
					max-width: 12rem;
					margin: auto;
				}
			}

			.detail-icon {
				width: 8rem;
				height: 8rem;


			}
		}
	}
}

.pmc-purchase {
	color: black;
	text-align: center;
	background-image: url('../../pm_coffee/img/product-shot-bg.jpg');
	background-size: cover;
	padding: 1rem 0;
	min-height: 29rem;

	.pmc-section-content {
		padding: 3rem 0;
		position: relative;
		margin: 0 auto;
		@include breakpointMax(436px) {
			overflow: hidden;
		}

		.purchase-text {
			margin: 0 auto 25rem;
			position: relative;
			z-index: 1;
		}

		.product-shot {
			width: 436px;
			height: 250px;
			background-image: url('../../pm_coffee/img/coffee-bag.png');
			background-size: contain;
			position: absolute;
			bottom: 0;
			left: 50%;
			top: auto;
			margin-left: -218px;
		}

		h2 {
			margin-bottom: 0;
			padding: 0 1rem;
		}
	}

	button.cta-button {
		margin: 3rem auto 3rem;
		background-color: black;
		border: none;
		color: white;
		padding: 1rem 2rem;

		&:hover {
			background-color: $darkest-gray;
		}
	}

	@include breakpointMin(532px) {
		background-size: contain;
	}

	@include breakpointMin(600px) {
		.pmc-section-content {

			.purchase-text {
				margin: 7rem 0 0 30%;
				position: relative;
				z-index: 1;
			}

			.product-shot {
				left: 20%;
				top: 2rem;
				bottom: auto;
				margin-left: -218px;
			}
		}
	}
}

.pmc-footer {
	font-size: 1.4em;
	color: white;

	.translation-icon{
		color: $black;
		background-color: white;
	}

	.icon {
		path {
			fill: currentColor;
		}
	}

	h3 {
		margin: 0;
		padding: 0;
	}

	&:after {
		content: '';
		display: block;
		clear: both;
		overflow: hidden;
	}

	.sa-partner-logo {
		font-size: 1.5em;
		margin-top: -.325em;
	}
	.row{
		margin: 1rem 0 4rem 0;
	}

	p{
		margin-bottom: 0;
		margin-top:  .75em;
	}
	@include breakpointMax(700px + 1) {
		.translation-dropdown{
			left: 0;
			right: auto;
		}
	}
	@include breakpointMin(700px) {
		.col-left,
		.col-right {
			width: calc(50% - 3rem);
			text-align: center;
		}
		.col-left {
			float: left;
		}

		.col-right {
			float: right;
		}
	}
}
