@font-face {
    font-family: 'Deming-EP';
    src: url('../../common/Deming-EP/fonts/Deming-EP.eot');
    src: url('../../common/Deming-EP/fonts/Deming-EP.eot?#iefix') format('embedded-opentype'),
        local('Deming-EP'),
        url('../../common/Deming-EP/fonts/Deming-EP.woff2') format('woff2'),
        url('../../common/Deming-EP/fonts/Deming-EP.woff') format('woff'),
        url('../../common/Deming-EP/fonts/Deming-EP.ttf') format('truetype'),
        url('../../common/Deming-EP/fonts/Deming-EP.svg#deming_epregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
